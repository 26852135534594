
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '../../scss-variables.scss';

.inlineButton {
  padding: 0 10px;
  min-height: 45px;

  .icon {
    @include iconLarge(rgb(var(--jet)));
  }

  .text {
    font-size: var(--font-size-s);
    margin-right: 3px;

    /* stylelint-disable-next-line custom/enforce-breakpoint-mixin */
    @media screen and (max-width: #{$wide-desktop-for-buttons-breakpoint}) {
      display: none;
    }
  }
}
